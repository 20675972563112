import React, { useEffect, useState } from 'react';
import { Product } from '../../../app/models/Product';
import { Place } from '../../../app/models/Place';
import { Unit } from '../../../app/models/Unit';
import { Currency } from '../../../app/models/Currency';
import { Service } from '../../../app/models/Service';
import useTransportationOrderItemService from '../../../app/services/hooks/useTransportationOrderItemService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import {
    defaultTransportationOrderCreateItemDataForm,
    defaultTransportationOrderItemDataForm,
    TransportationOrderItemCreateDataForm,
    TransportationOrderItemDataForm
} from '../../../app/models/TransportationOrderItem';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import TransportationOrderItemForm from './forms/TransportationOrderItemForm';
import LazyLoading from '../../../components/LazyLoading';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { Routes } from '../../../app/models/RouteSegment';
import useQuoteRouteSegmentService from '../../../app/services/hooks/useQuoteRouteSegmentService';
import TransportationOrderRatesForm from './forms/TransportationOrderRatesForm';
import useRouteSegmentService from '../../../app/services/hooks/useRouteSegmentService';

interface Props {
    canBeEdited: boolean;
    transportationOrderId: number;
    currencyId: number | string;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const TransportationOrderItemCreate = ({
    canBeEdited,
    transportationOrderId,
    currencyId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const {
        createTransportationOrderItem,
        storeTransportationOrderItem,
        fetchingCreateTransportationOrderItem,
        fetchingStoreTransportationOrderItem
    } = useTransportationOrderItemService();

    const {
        fetchingGetQuoteRouteSegment,
        fetchingShowRsToTransportationQuoteDetail,
        getQuoteRouteSegment,
        showRsToTransportationQuoteDetail
    } = useQuoteRouteSegmentService();

    const [errorFields, setErrorFields] = useState<any>();

    const [transportationOrderItemForm, setTransportationOrderItemForm] =
        useState<TransportationOrderItemDataForm>({
            ...defaultTransportationOrderItemDataForm,
            ['transportation_order_id']: transportationOrderId,
            ['quantity']: 0,
            ['route_id']: 0
        });
    const [transportationOrderCreateItemForm, setTransportationOrderCreateItemForm] =
        useState<TransportationOrderItemCreateDataForm>({
            ...defaultTransportationOrderCreateItemDataForm,
            ['route_id']: 0,
            ['agreed_distance']: 0
        });
    const [products, setProducts] = useState<Product[]>([]);
    const [quantityUnits, setQuantityUnits] = useState<Unit[]>([]);
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [routes, setRoutes] = useState<Routes[]>([]);
    const [hasQuote, setHasQuote] = useState<boolean>(false);

    useEffect(() => {
        create();
    }, []);

    // useEffect(() => {
    //     if (services.length) {
    //         setTransportationOrderItemForm({
    //             ...transportationOrderItemForm,
    //             service_id: services[0].id
    //         });
    //     }
    // }, [services]);

    useEffect(() => {
        if (hasQuote) {
            if (
                transportationOrderItemForm.route_id &&
                transportationOrderCreateItemForm.route_id != transportationOrderItemForm.route_id
            ) {
                getQuoteRouteSegment(transportationOrderItemForm.route_id, {
                    onSuccess: (response: ServiceResponse) => {
                        setErrorFields(undefined);
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            ...defaultTransportationOrderCreateItemDataForm,
                            ...response.data.quote_route_segment
                        });
                    }
                });
            } else if (
                transportationOrderItemForm.route_id &&
                transportationOrderCreateItemForm.route_id == transportationOrderItemForm.route_id
            ) {
                setErrorFields(undefined);

                setTransportationOrderItemForm({
                    ...transportationOrderItemForm,
                    ...defaultTransportationOrderCreateItemDataForm,
                    ...transportationOrderCreateItemForm
                });
            }
        } else {
            if (transportationOrderItemForm.route_id) {
                showRsToTransportationQuoteDetail(transportationOrderItemForm.route_id, {
                    onSuccess: (response: ServiceResponse) => {
                        setErrorFields(undefined);
                        setTransportationOrderItemForm({
                            ...transportationOrderItemForm,
                            ...defaultTransportationOrderCreateItemDataForm,
                            ...response.data.route_segment
                        });
                    }
                });
            }
        }
    }, [transportationOrderItemForm.route_id]);

    const create = () => {
        createTransportationOrderItem(transportationOrderId, {
            onSuccess: (response: ServiceResponse) => {
                setProducts(response.data.products);
                setQuantityUnits(response.data.quantity_units);
                setCurrencies(response.data.currencies);
                setServices(response.data.services);

                if (response.data.routes_data.quote_route_segment) {
                    setRoutes(response.data.routes_data.routes);
                    setHasQuote(true);
                    setTransportationOrderItemForm({
                        ...transportationOrderItemForm,
                        route_id: response.data.routes_data.quote_route_segment.id,
                        ...response.data.routes_data.quote_route_segment
                    });
                    setTransportationOrderCreateItemForm({
                        ...transportationOrderCreateItemForm,
                        route_id: response.data.routes_data.quote_route_segment.id,
                        ...response.data.routes_data.quote_route_segment
                    });
                } else {
                    setRoutes(response.data.routes_data.routes);
                }
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando detalle pedido...', 400);
        storeTransportationOrderItem(transportationOrderItemForm, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
             
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    {!fetchingCreateTransportationOrderItem ? (
                        <>
                            <TransportationOrderItemForm
                                products={products}
                                quantityUnits={quantityUnits}
                                currencies={currencies}
                                services={services}
                                routes={routes}
                                hasQuote={hasQuote}
                                transportationOrderItemForm={transportationOrderItemForm}
                                setTransportationOrderItemForm={setTransportationOrderItemForm}
                                errorFields={errorFields}
                                canBeEdited={true}
                                fetching={
                                    fetchingGetQuoteRouteSegment ||
                                    fetchingShowRsToTransportationQuoteDetail
                                }
                            />
                            {/*<TransportationOrderRatesForm*/}
                            {/*    currencies={currencies}*/}
                            {/*    quantityUnits={quantityUnits}*/}
                            {/*    transportationOrderitemForm={transportationOrderItemForm}*/}
                            {/*    setTransportationOrderItemForm={setTransportationOrderItemForm}*/}
                            {/*    errorFields={errorFields}*/}
                            {/*/> */}
                        </>
                    ) : (
                        <LazyLoading height="300" />
                    )}
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreTransportationOrderItem}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={store}
                        locked={fetchingStoreTransportationOrderItem}
                    />
                </div>
            </div>
        </>
    );
};

export default TransportationOrderItemCreate;
